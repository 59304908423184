import React, {
    // useState, 
    // useEffect, 
    // useMemo, 
    // useCallback, 
    // useRef
} from 'react';
import {
    useHistory, 
    // useLocation, 
    // Link, 
    // Switch, 
    // Route
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    API_ROOT, 
    // BASE
} from '../api.js';
import { useDataContext } from '../DataProvider';
import {readFileAsync} from '../util';
import _ from 'lodash';


async function importExcel(e, url, user) {
    const files = e.target.files;
    if (files.length === 0)
        return;

    const content = await readFileAsync(files[0]);
    let res = await fetch(`${API_ROOT}/${url}`, {
        method: 'POST',
        headers: {
            'Accept': '*',
            'Authorization': `JWT ${user.token}`,
            'Content-Type': 'application/octet-stream'
        },
        body: content
    });
    res = await res.json();
    if (res.result) {
        window.alert("Файл отправлен успешно");
        return;
    }
    window.alert(JSON.stringify(res));
};


const UploadControl = React.memo(({id, url}) => {
    const {user, loading} = useDataContext();

    if (loading || !_.get(user, ['rights', '/'+url, 'post']))
        return null;

    return(
        <div 
            className="ImportControl"  
            style={{ alignSelf: "flex-end" }}
        >
            <button  
                onClick={() => document.getElementById(`input-file-${id}`).click()}
            >
                <FontAwesomeIcon icon="file-upload" />
            </button>
            <input 
                type="file" 
                id={`input-file-${id}`} 
                style={{display: 'none'}} 
                onChange={(e) => importExcel(e, url, user)}  
            />
        </div>
    )
})

export default function ReportsPane() {
    const history = useHistory();

    return(
        <div className="ReportsPane">
            <div className="head">
                <h2>Отчёты</h2>
                <div className="controls">
                    <button className="close" onClick={history.goBack}>
                        <FontAwesomeIcon icon="times" size="lg" />
                    </button>
                </div>
            </div>
            <div className="List">
                <div style={{display: "flex"}}>
                    <button 
                        // onClick={() => window.open(`${API_ROOT}/reports/residential`)}
                        onClick={() => window.open(`${API_ROOT}/static/waste_source.xlsx`)}
                    >
                        Источники образования отходов.
                    </button>
                    <UploadControl id='waste_source' url="static/waste_source.xlsx" />
                </div>
                {/* <button onClick={() => window.open(`${API_ROOT}/reports/organizational`)}>Источники образования отходов — ЮЛ и ИП</button> */}
                <div style={{display: "flex"}}>
                    <button 
                        // onClick={() => window.open(`${API_ROOT}/reports/container_grounds`)}
                        onClick={() => window.open(`${API_ROOT}/static/container_areas.xlsx`)}
                    >
                        Места накопления отходов — контейнерные площадки.
                    </button>
                    <UploadControl id='container_areas' url="static/container_areas.xlsx" />
                </div>
                {/* <button onClick={() => window.open(`${API_ROOT}/reports/containerless_collection`)}>Места накполения отходов — бесконтейнерный сбор</button> */}
                <div style={{display: "flex"}}>
                    <button 
                        // onClick={() => window.open(`${API_ROOT}/reports/facilities`)}
                        onClick={() => window.open(`${API_ROOT}/rstatic/waste_obj.xlsx`)}
                    >
                        Объекты обращения с отходами.
                    </button>
                    <UploadControl id='waste_obj' url="static/waste_obj.xlsx" />
                </div>
                <div style={{display: "flex"}}>
                    <button 
                        // onClick={() => window.open(`${API_ROOT}/reports/capex`)}
                        onClick={() => window.open(`${API_ROOT}/static/volume_estimate.xlsx`)}
                    >
                        Оценка объема соответствующих капитальных вложений в строительство, реконструкцию, выведение из эксплуатации объектов обработки,утилизации, обезвреживания, размещения отходов.
                    </button>
                    <UploadControl id='volume_estimate' url="static/volume_estimate.xlsx" />
                </div>
                {/* <button onClick={() => window.open(`${API_ROOT}/reports/balance_detailed`)}>Баланс потоков ТКО по МО</button> */}
                {/* <button onClick={() => window.open(`${API_ROOT}/reports/balance`)}>Баланс потоков ТКО по региону</button> */}
                <div style={{display: "flex"}}>
                    <button 
                        onClick={() => window.open(`${API_ROOT}/static/waste_gen.xlsx`)}
                    >
                        Количество образующихся отходов.
                    </button>
                    <UploadControl id='waste_gen' url="static/waste_gen.xlsx" />
                </div>
                <div style={{display: "flex"}}>
                    <button 
                        onClick={() => window.open(`${API_ROOT}/static/balance.xlsx`)}
                    >
                        Баланс количественных характеристик образования, обработки, утилизации, обезвреживания, размещения отходов
                    </button>
                    <UploadControl id='balance' url="static/balance.xlsx" />
                </div>
                <div style={{display: "flex"}}>
                    <button 
                        onClick={() => window.open(`${API_ROOT}/static/targets.xlsx`)}
                    >
                        Целевые показатели по обезвреживанию, утилизации и размещению отходов
                    </button>
                    <UploadControl id='targets' url="static/targets.xlsx" /> 
                </div>
                <div style={{display: "flex"}}>
                    <button 
                        onClick={() => window.open(`${API_ROOT}/static/waste_success.xlsx`)}
                    >
                        Применяемая схема потоков твердых коммунальных отходов.
                    </button>
                    <UploadControl id='waste_success' url="static/waste_success.xlsx" />
                </div>
                <div style={{display: "flex"}}>
                    <button 
                        onClick={() => window.open(`${API_ROOT}/static/waste_predict.xlsx`)}
                    >
                        Перспективная схема потоков твердых коммунальных отходов.
                    </button>
                    <UploadControl id='waste_predict' url="static/waste_predict.xlsx" />
                </div>
                <div style={{display: "flex"}}>
                    <button 
                        onClick={() => window.open(`${API_ROOT}/static/plane_data.xlsx`)}
                    >
                        Данные о планируемом строительстве, реконструкции, выведении из эксплуатации объектов обработки, утилизации, обезвреживания, размещения отходов.
                    </button>
                    <UploadControl id='plane_data' url="static/plane_data.xlsx" />
                </div>
                <div style={{display: "flex"}}>
                    <button 
                        onClick={() => window.open(`${API_ROOT}/static/tariff_predict.xlsx`)}
                    >
                        Прогнозные значения предельных тарифов в области обращения с твердыми коммунальными отходами.
                    </button>
                    <UploadControl id='tariff_predict' url="static/tariff_predict.xlsx" />
                </div>
            </div>
        </div>
    )
}