// import _ from 'lodash'

export let BASE
export let API_ROOT

if (process.env.NODE_ENV === 'production'){
	// API_ROOT = '';
	// BASE = '';
	BASE = 'vladimir';
	API_ROOT = `https://api.${BASE}.zig-zag.org`;
} else {
	// BASE = 'kostroma';
	BASE = 'vladimir';
	API_ROOT = `https://api.${BASE}.zig-zag.org`;
	// API_ROOT = `http://localhost:8080`;

}

export async function apiRequest(method, url, params, headers) {
	const res = await fetch(API_ROOT+url, {
        method: method,
        headers: {
			'Accept': '*',
			'Content-Type': 'application/json',
			...(headers || {})
        },
        body: JSON.stringify(params)
	});
    return await res.json();
}


const errors = {
	1001: "Не заполнено обязательное поле",
	1002: "Объект уже существует",
	1003: "Нет такого пользователя",
	1004: "Неверный авторизационный токен",
	1005: "Не найдено",
	1006: "Необходимо авторизоваться",
	1007: "Недостаточно прав",
	1008: "Ошибка роутера"
};

export function decodeError(error) {
  return errors[String(error.code)];
}